<template>
    <div class="cards-container">
        <Hooper :settings="hoopersSetting" class="cards-container">
                <Slide v-for="(wallet,index) in wallets" :key="index" class="card">
                    <div class="card">
                        <img :src="require('../../../assets/Cards/'+ wallet.relatedCoin +'.png')" alt="" class="card-background">
                        <div class="title">
                            <img :src="'/coins/'+ wallet.relatedCoin +'.png'" alt="" class="coin-badge">
                            <div class="text">
                                <span class="faName"> {{$coinLabel[wallet.relatedCoin]}} </span>
                                <span class="Unit"> {{$coinUnit[wallet.relatedCoin]}} </span>
                            </div>
                        </div> 
                        <div class="balanceRow">
                            <span class="balancetitle"> موجودی شما </span>
                            <!-- <span class="balance">  {{$toLocal(wallet.credit,2)}} تتر </span> -->
                            <span class="balance"> 0 </span>
                        </div>
                        <div class="balanceRow">
                            <span class="coinBalance"> <span class="s2s"> {{$coinUnit[wallet.relatedCoin]}}</span> {{$toLocal(wallet.credit,$decimal[wallet.relatedCoin])}} </span>
                            <span class="sign"> ~ </span>
                            <span class="balancetitle"> {{$toLocal(wallet.wholePriceOfCredit,0)}} تومان </span>
                        </div>
                    </div>
                </Slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>  
        </Hooper>
    </div>
</template>

<script>
import {Hooper, Slide,Navigation as HooperNavigation} from 'hooper'
import 'hooper/dist/hooper.css';
    export default {
        name:'Cards',
        props:['wallets'],
        components: {
            Hooper,
            Slide,
            HooperNavigation
        },
        data() {
            return {
                    balance:40000,
                    coinBalance:0.1,
                    coin:'BTC',
                    tomanBalance:123456789,
                    fromRight:0,
                    hoopersSetting:{

                        rtl:true,
                        infiniteScroll:true,
                        autoPlay:false,
                        autoplaySpeed: 2000,
                        itemsToShow: 6,
                        centerMode:false,
                        breakpoints : {
                        1200:{
                            itemsToShow: 4,
                            centerMode:true,
                        },
                        800:{
                            itemsToShow: 3,
                            centerMode:true,
                        },
                        500:{
                            itemsToShow:2,

                        },
                        320:{
                            itemsToShow:1,
                            centerMode:true,
                        }
                    }
                    },
                      responsive: [
                        {
                          "breakpoint": 1200,
                          "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1,
                            "infinite": true,
                            "dots": false
                          }
                        },
                        {
                          "breakpoint": 992,
                          "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 1,
                            "infinite": true,
                            "dots": false,
                            "arrow": false
                          }
                        },
                        {
                          "breakpoint": 600,
                          "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "initialSlide": 2,
                            "arrows": false
                          }
                        },
                        {
                          "breakpoint": 480,
                          "settings": {
                            "slidesToShow": 1,
                            "slidesToScroll": 1,
                            "initialSlide": 1,
                            // "centerMode": true,
                            "arrows": false
                          }
                        }
                      ],
                    settings: {
                        rtl: true,
                        arrows: true,
                        autoplay: true,
                        dots: false,
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        autoplaySpeed: 600,
                        useCSS: true,
                        // centerMode:true,
                    }


            }
        },
        methods: {
            toRight(){
                const classNames = document.getElementsByClassName('card');
                if(this.fromRight==2750){
                    this.fromRight=-250
                    
                }
                for(let i in classNames){
                    if(i>-1 && i<12){
                        classNames[i].style.transform=`translate(${this.fromRight+250}px,0)`;
                    }
                }
                this.fromRight=this.fromRight+250;
            },
            toLeft(){
                const classNames = document.getElementsByClassName('card');
                if(this.fromRight==0){
                    this.fromRight=3000
                    
                }
                for(let i in classNames){
                    if(i>-1 && i<12){
                        classNames[i].style.transform=`translate(${this.fromRight-250}px,0)`;
                    }
                }
                this.fromRight=this.fromRight-250;
            },
        },
    }
</script>

<style lang="scss" scoped>
.sidearrow{
    width: 80px;
    height: 80px;
    cursor: pointer;
}
h2{
    text-align: start;
    align-self: flex-start;
}
.coin-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    height: 100%;
    .coin-label{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .coin-icon{
            display: flex;
            flex-direction: row;
            column-gap: 3px;
            img{
                width: 40px;
                height: 40px;
            }
            .coinunit{
                display: flex;
                flex-direction: column;
                // row-gap: px;
                .unit{
                    font-weight: 400;
                    font-size: clamp(10px,1vw,12px);
                    color: var(--a-hover);
                }
            }
        }
        .coinchange{
            display: flex;
            flex-direction: column;
            // align-items: center;
            // column-gap: 8px;
            .changetime{
                align-self: flex-end;
                font-weight: 400;
                font-size: clamp(10px,1vw,12px);
                color: rgba(132, 110, 110, 1);
            }
            .change{
                display: flex;
                flex-direction: row;
                column-gap: 3px;
                font-weight: 400;
                font-size: clamp(14px,2vw,16px);
            }
        }
    }
    .price{
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        align-self: flex-end;
        .tetherprice{
            font-weight: 700;
            font-size: clamp(16px,2vw,20px);
            text-align: end;
        }
        .tomanprice{
            font-weight: 400;
            font-size: clamp(10px,1vw,12px);
            text-align: end;
        }
    }
}
.hooper-item{
    &:hover{
        box-shadow: 0px 0px 14px -1px rgba(65, 70, 192, 0.47);
    }
    .chart{
        position: absolute;
        opacity: .5;
        height: 100%;
        width: 100%;
    }
}
.carusel-container{
    z-index: 3;
    position: relative;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
    align-items: center;
    justify-content: center;
    padding:40px 120px;
    .items-container{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px 5px;
        align-items: center;
        column-gap: 20px;
        overflow: scroll;
        // padding: 20px 0;
        .chart{
            position: absolute;
            height: 50%;
            bottom: 15px;
            right: 0;
            width: 100%;
            // padding:0 20px;
        }
    }
}
@media only screen and(max-width:1000px){
    .carusel-container{
        padding: 20px !important;
    }
}
@media only screen and(max-width:500px){
        .sidearrow{
            display: none;
        }
}
.carousel {
  text-align: center;
  width: 100%;
  align-self: center;
}

.card {
  box-shadow: unset !important;
  border-radius: 8px;
  max-width: 248px;
  height: 147px;
  z-index: 2;
  padding: 28px;
  &:hover{
      box-shadow: unset !important;
  }
}

.price {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-self: center;

  .tetherprice {
    font-weight: 700;
    font-size: clamp(16px, 2vw, 20px);
    text-align: center;
    background: none !important;
  }

  .tomanprice {
    font-weight: 400;
    font-size: clamp(10px, 1vw, 12px);
    text-align: center;
    background: none !important;
  }
}

.chart {
  height: 40px;
  width: 35%;
}
</style>

<style lang="scss">
//.slick-slider {
//  display: flex !important;
//  justify-content: center !important;
//}
//
//.slick-list {
//  width: 80% !important;
//  padding: 20px 0 !important;
//}

.slick-list {

  height: 160px !important;
}


@media(max-width: 450px){
  .card{
    max-width: unset;
    width: 100%;
    margin: 0 auto;
  }
}
.sidearrow{
    width: 80px;
    height: 80px;
    cursor: pointer;
}
.cards-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    overflow: scroll;
    z-index: 2;
    width: 100%;
    .card{
        &:hover{
            box-shadow: unset !important;
        }
        box-shadow: unset !important;
        transition: 700ms;
        position: relative;
        padding: 13px 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 250px;
        height: 140px;
        .balanceRow{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .s2s{
                color: var(--disabled) !important;
                font-weight: 400 !important;
                font-size: clamp(10px,1vw,12px) !important;
            }
            .coinBalance{
                color: var(--white);
                font-weight: 700;
                font-size: clamp(18px,2vw,20px);
            }
            .sign{
                color: var(--disabled);
                font-weight: 400;
                font-size: clamp(10px,1vw,12px);
            }
            .balancetitle{
                color: var(--disabled);
                font-weight: 400;
                font-size: clamp(10px,1vw,12px);
            }
            .balance{
                color: var(--white);
                font-weight: 700;
                font-size: clamp(18px,2vw,20px);
            }
        }
        .title{
            padding-top: 12px;
            width: 250px;
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            align-items: center;
            .text{
                display: flex;
                flex-direction: column;
                // row-gap: 5px;
                .faName{
                    color: var(--white);
                    font-weight: 500;
                    font-size: clamp(10px,1vw,12px);
                }
                .Unit{
                    color: rgba(219, 220, 232, 1);
                    font-weight: 500;
                    font-size: clamp(10px,1vw,12px);
                }
            }
        }
        .coin-badge{
            width: 30px;
            height: 30px;
        }
        .card-background{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}
@media only screen and(max-width:500px){

        .title{
            width: 150px !important;
        }
        .sidearrow{
            display: none;
        }
}
</style>