<template>
    <div class="apex-container">
        <ApexCharts  type="donut" width="100%" height="300px" :options="chartOptions" :series="series"
            :key="series.length + '' + chartOptions.labels.length + change+ wallets"/>
    </div>
</template>

<script>
    export default {
        name:'ApexChart',
        props:['wallets','balanceWallet'],
        data() {
            return {
                series: [],

                change: 0,

                chartOptions: {

                    chart: {
                    height:'213px',
                    fontFamily: 'IRANSans',
                    fontSize:'16px',
                    color: 'rgb(72, 77, 86)',
                    },

                    colors: [
                    '#E57373', '#81C784', '#4DD0E1', '#7986CB', '#BA68C8', '#FF9ACC', '#FFB74D', '#F7EA72', '#4DB6AC', '#90A4AE'
                    ],

                    theme: {
                    mode: 'light',
                    palette: 'palette2'
                    },

                    stroke: {
                    width: 1,
                    colors: undefined,
                    lineCap:'round'
                    },

                    labels: [],

                    dataLabels: {
                    enabled: false,
                    style: {
                        fontFamily: 'IRANSans'
                    }
                    },

                    legend: {
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 0,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    show: true,
                    customLegendItems: [],
                    position: 'left',
                    },

                    plotOptions: {
                    pie: {
                        offsetX: 0,
                        offsetY: 0,
                        donut: {
                        size: '75%',
                        background: 'rgba(240, 240, 245, 1)',
                        labels: {
                            show: true,
                            name: {
                            show: true,
                            fontSize: '12px',
                            fontFamily: 'IRANSans',
                            fontWeight: 600,
                            color: 'var(--tooDark)',
                            offsetY: -17,
                            formatter: function (val) {
                                return val.name || val
                            }
                            },
                            value: {
                            show: true,
                            fontSize: '12px',
                            fontFamily: 'IRANSans',
                            fontWeight: 400,
                            color: '#484D56',
                            offsetY: 10,
                            formatter: function (val) {
                                return parseFloat(val).toLocaleString()
                            }
                            },
                            total: {
                            show: true,
                            showAlways: false,
                            label: 'ارزش کل دارایی',
                            fontSize: '16px',
                            fontFamily: 'IRANSans',
                            fontWeight: 600,
                            color: '#484D56',
                            formatter: function (w) {
                                w = w.globals.seriesTotals.reduce((a, b) => a + b)
                                return w.toLocaleString() + '\n' + 'تتر'
                            }
                            }
                        }
                        },
                    }
                    },

                    tooltip: {
                    enabled: true,
                    fillSeriesColor: true,
                    custom: function ({
                                        seriesIndex,
                                        w
                                        }) {
                        return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
                    }
                    },

                    responsive: [
                    {
                        breakpoint: 800,
                        options: {
                            chart: {
                            width:'100%',
                            fontFamily: 'IRANSans',
                            fontSize:'16px',
                            color: 'rgb(72, 77, 86)',
                            },
                        plotOptions: {
                            bar: {
                            // horizontal: false
                            },

                            pie: {
                            offsetX: 0,
                            offsetY: 0,
                            donut: {
                                size: '75%',
                                background: 'white',
                                labels: {
                                // show: false,
                                }
                            },
                            }
                        },
                        legend: {
                            show:false
                        },
                        }
                    }
                    ]
                },
                }
        },
        methods:{
            async getWallets() {
                    const res = await this.$axios.get('/wallets/customer-addresses')
                    let entireRes = Object.entries(res)
                    const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN']
                    entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                    this.wallets = entireRes
            },


            chart() {
                let index = 0
                this.wallets.forEach((e) => {
                    if (e[0] !=='TOMAN') {
                    this.chartOptions.labels[index] = {
                        'name': this.$coinUnit[e[0]],
                        'value': e[1].credit || 0
                    }
                    this.series[index] = e[1].totalBalance || e[1].wholePriceOfCredit || 0;

                    this.chartOptions.legend.customLegendItems[index] = (e[1].confirmedCredit ? e[1].confirmedCredit + this.$coinUnit[e[0]] : 0 + this.$coinUnit[e[0]])
                    index++
                    }
                })
                ++this.change

            },

            async getData() {
                console.log(this.balanceWallet);
                this.balanceWallet.map( wallet => {
                    let name = this.$coinLabel[wallet.relatedCoin] + '(' + wallet.relatedCoin +')'
                    this.series.push(wallet.wholePriceOfCredit)
                    this.chartOptions.labels.push(name)
                    
                })
                console.log(this.chartOptions.labels);
                console.log(this.series);
            },

        },
        mounted(){
            this.wallets ? this.getData() : ''
        }
    }
</script>

<style lang="scss" scoped>
.apex-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65%;
    height: 100%;
}
@media only screen and(max-width:1000px){
    .apex-container{
        width: 100% !important;
        justify-content: center;
    }
}
</style>