<template>
    <div class="activeorder-container">
        <h3> سفارشات فعال </h3>
        <div class="table-container">
            <perfect-scrollbar @ps-y-reach-end="getOrders">
            <table>
                <tr class="head">
                    <td style="text-align:start;padding-right:10px"> بازار </td>
                    <td class=""> سمت </td>
                    <td class=""> مبلغ کل </td>
                    <td class=""> پر شده </td>
                    <td style="text-align:end"> 
                        <div class="cancel-container">
                            <button @click.prevent="candelAllOrder" class="fail-btn"> لغو همه </button>     
                        </div>
                    </td>
                </tr>
                <tr v-for="(item,index) in tradeHistory" :key="index" class="body">
                    <td style="text-align:start;padding-right:10px"> {{item.marketType}} </td>
                    <td class="" :class="item.orderType.includes('BUY') ? 'color-green':'fail'" > {{item.orderType.includes('BUY') ? ' خرید ':' فروش '}} </td>
                    <td class=""> {{$toLocal(item.wholePrice)}} </td>
                    <td class=""> {{$toLocal(item.executedPercent,2)}}% </td>
                    <td @click="candelOrder(item.id)" class="fail" style="text-align:end;padding-left:14px;cursor:pointer"> لغو سفارش </td>
                </tr>
            </table>
            </perfect-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        name:'DashActiveOrders',
        data() {
            return {
                tradeHistory:[],
                page:0,
                lastPage:1,
            }
        },
        methods: {
            async candelAllOrder() {
                this.$swal.fire({
                        title: 'لغو سفارش',
                        text: "تمام سفارشات شما لغو خواهد شد و قابل برگشت نخواهد بود ",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'بازگشت',
                        confirmButtonText: 'لفو سفارشات'
                        }).then(async (result) => {
                        if (result.isConfirmed) {
                            this.state.loading = true
                            const [res,] = await this.$http.delete(`orders/all?marketType=${this.$route.params.tradeFrom+'_'+this.$route.params.tradeTo}`)
                            console.log(res);
                            if (res) {
                                ++this.state.orderChanged
                                this.getOrders()
                                this.$swal.fire(
                                'لغو همه سفارشات',
                                'سفارشات شما با موفقیت لغو شد',
                                'success'
                                )
                                this.state.orderChanged++
                            }
                        }
                 })
            },
            async candelOrder(e) {
                this.$swal.fire({
                        title: 'لغو سفارش',
                        text: "سفارش شما لغو خواهد شد و قابل برگشت نخواهد بود ",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'بازگشت',
                        confirmButtonText: 'لفو سفارش'
                        }).then(async (result) => {
                        if (result.isConfirmed) {
                            this.state.loading = true
                            const [res,] = await this.$http.delete(`orders/${e}`)
                            console.log(res);
                            if (res) {
                                ++this.state.orderChanged
                                this.getOrders()
                                this.$swal.fire(
                                'لغو سفارش',
                                'سفارش شما با موفقیت لغو شد',
                                'success'
                                )
                            }
                        }
                 })
            },
            async getOrders(){
                if (this.page < this.lastPage) {
                    this.page++
                const res = await this.$axios.get('/orders', {
                    params: {
                        orderStatus: 'IS_OPEN',
                        size:10,
                    }
                })
                this.lastPage = res.totalPages
                let newData = res.content.filter(a => a.orderStatus == 'IS_OPEN')
                this.tradeHistory=[...this.tradeHistory,...newData]
                }
            },
        },
        mounted() {
            this.getOrders();
        },
    }
</script>

<style lang="scss" scoped>
.table-container{
    height: 100%;
    overflow: scroll;
}
.cancel-container{
    display: flex;
    align-items: flex-end;
    justify-content: end;
    width: 100%;
}
.body{
    border-radius: 4px;
    transition: 700ms;
    &:hover{
        background: var(--white);
        box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    }
}
.fail-btn{
    background: var(--red);
    color: var(--white);
    font-weight: 500;
        font-size: clamp(8px,1vw,12px);
}
table{
    width: 100%;
    min-width: 400px;
    .head{
        background: var(--white);
        position: sticky;
        top: 0px;
        td{
            color: var(--a-hover);
            height: 65px !important;
            vertical-align: sub;
        }
    }
    td{
        width: 16%;
        text-align: center;
        height: 45px;
        vertical-align:middle;
        font-weight: 500;
        font-size: clamp(8px,1vw,12px);
    }
}
.activeorder-container{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding:18px 24px;
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    width: 65%;
    max-height: 410px;
    h3{
        position: sticky;
        top: 0;
        background: #F0F0F5;
        color: var(--black);
        font-weight: 700;
        font-size: clamp(14px,2vw,16px);
    }
}
@media only screen and(max-width:900px){
    .activeorder-container{
        overflow: scroll;
    }
}
</style>