<template>
    <div class="dashboard">


        <div class="titles">
            <div class="levels">
                <div class="line1"></div>
                <span class="box1">
                    سطح کاربری ۱
                </span>
                <div class="line2"></div>
                <span class="box2">
                    سطح کاربری ۲
                </span>
                <div class="line3"></div>
                <span class="box3">
                    سطح کاربری ۳
                </span>
                <div class="line4"></div>
                <span class="box4">
                    سطح کاربری ۴
                </span>
                <div class="line5"></div>
            </div>
            <div class="pricebtns">
                                <button @click="tomanClick=true" :class="{'select':tomanClick}" class="toman"> تومان </button>
                                <button @click="tomanClick=false" :class="{'select':!tomanClick}" class="tether"> تتر </button>
            </div>
        </div>


        <div class="content">
            <div class="top-row">
                <ApexChart :key="wallets+balanceWallet+'apex'" :wallets="wallets" :balanceWallet="balanceWallet"/>
                <div class="wallet-details">
                    <div @click="$router.push('/dashboard/invitefriends')" class="wallet-item">
                        <img src="../../../assets/Icons/dashWallet.svg" alt="">
                        <div class="text">
                            <span class="title"> ارزش کل دارایی </span>
                            <span class="balance"> تومان {{$toLocal(balancePrice,0)}} </span>
                        </div>
                    </div>
                    <div class="wallet-item">
                        <img src="../../../assets/Icons/dashInvite.svg" alt="">
                        <div class="text">
                            <span class="title"> دریافتی از دعوت ها </span>
                            <span class="balance"> تومان {{$toLocal(profit,0)}} </span>
                        </div>
                    </div>
                </div>
            </div>
            <Cards :wallets="wallets"/>   
            <div class="tables">
                <DashMarket  />
                <DashActiveOrders />
            </div>
        </div>
    </div>
</template>

<script>
import ApexChart from './ApexChart.vue';
import Cards from './Cards.vue';
import DashMarket from './DashMarket.vue';
import DashActiveOrders from './DashActiveOrders.vue';
    export default {
        name:'Dashboard',
        components:{
            ApexChart,
            Cards,
            DashMarket,
            DashActiveOrders,
        },
        data() {
            return {
                tomanClick:false,
                profit:0,
                tether:null,
                toman:null,
                wallets:null,
                balancePrice:0,
                balanceWallet:[],
            }
        },
        methods: {
            async inviteFriends(){
                this.state.loading=true;
                const res = await this.$axios.get('/users/invites')
                this.profit=res.baseDTO.profit

            },
            async getWallets() {
                    this.state.loading=true
                    const res = await this.$axios.get('/wallets/customer-addresses?walletType=P2P')
                    let entireRes = Object.entries(res)
                    this.sortWallets(entireRes);
            },
            sortWallets(e){
                        const sortCoins = ['BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN','TETHER','MATIC'];
                        e.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                        this.tether = e.filter(a => a[0] == 'TETHER')[0][1]
                        this.toman = e.filter(a => a[0] == 'TOMAN')[0][1]
                        this.wallets = e.map(a => {
                                let relatedCoin = a[0]
                                if(a.relatedCoin) {
                                    a=a[1]
                                }else{
                                    a=a[1]
                                    a.relatedCoin=relatedCoin
                                }
                                return a
                                })
                            this.wallets=this.wallets.filter(wallet =>  wallet.relatedCoin !== 'TOMAN')
                            // this.wallets.sort((a,b) => b.credit - a.credit)
                            console.log(this.wallets);
                            this.creditPercent();
            },
            creditPercent(){
                this.balanceWallet = this.wallets.filter(a => a.credit > 0)
                this.balanceWallet.map(e => {
                    this.balancePrice += e.wholePriceOfCredit;
                });
                this.balanceWallet.map(e => {
                    e.percent = (e.wholePriceOfCredit / this.balancePrice) * 100
                });

            },
        },
        mounted() {
            this.inviteFriends()
            this.getWallets();
        },
    }
</script>

<style lang="scss" scoped>
.tables{
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    justify-content: space-between;
    flex-wrap: wrap;
}






.wallet-details{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    width: 30%;
    .wallet-item{
        transition: 700ms;
        max-width: 360px;
        padding: 18px 12px;
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        border-radius: 8px;
        &:hover{
            cursor: pointer;
            background: #F0F0F5;
            box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
        }
        .text{
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            .balance{
                color: var(--black);
                font-weight: 700;
                font-size: clamp(20px,2vw,24px);
            }
            .title{
                color: var(--a-default);
                font-weight: 700;
                font-size: clamp(14px,2vw,16px);
            }
        }
    }

}


.content{
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    .top-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}







.select{
    color: var(--primary) !important;
    background: var(--primary-light);
}
.pricebtns{
    display: flex;
    flex-direction: row;
    padding: 6px;
    // background: var(--white);
    background: transparent;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    max-width: 180px;
    width: 100%;
    button{
        color: var(--a-hover);
        font-weight: 500;
        font-size: clamp(10px,1vw,12px);
        width: 50%;
        // max-width: 130px !important;
    }
}








.box1{
    width: 20%;
    color: white;
    background: rgba(240, 148, 48, 0.7);
    border: 2px solid rgba(240, 148, 48, 1);
    text-align: center;
    height: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(10px,1vw,12px);
}
.box2{
    width: 20%;
    color: white;
    background: rgba(41, 135, 156, 0.6);
    border: 2px solid rgba(41, 135, 156, 1);
    text-align: center;
    height: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(10px,1vw,12px);
}
.box3{
    width: 20%;
    color: white;
    border: 2px solid rgba(38, 138, 255, 1);
    background: rgba(38, 138, 255, 0.6);
    text-align: center;
    height: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(10px,1vw,12px);
}
.box4{
    width: 20%;
    color: white;
    border: 2px solid rgba(110, 120, 255, 1);
    background: rgba(110, 120, 255, 0.6);
    text-align: center;
    height: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(10px,1vw,12px);
}
.levels{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 80%;
    height: 30px;
    border-right: 1px solid rgba(240, 148, 48, 1);
    border-left: 1px solid rgba(110, 120, 255, 1);
    .line5{
        width: 50px;
        border-bottom: 1px solid rgba(110, 120, 255, 1)
    }
    .line4{
        width: 50px;
        height: 1px;
        // border: 1px solid;
        background: linear-gradient(90deg, #6E78FF 0%, #268AFF 100%);
    }
    .line3{
        width: 50px;
        height: 1px;
        background: linear-gradient(90deg, #268AFF 0%, #29879C 100%);
    }
    .line2{
        width:50px;
        height: 1px;
        background: linear-gradient(90deg, #29879C 0%, #F09430 100%);
    }
    .line1{
        width:50px;
        border-bottom:1px solid rgba(240, 148, 48, 1);
    }
}











.titles{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.dashboard{
    display: flex;
    flex-direction: column;
    row-gap: 80px;
    width: 100%;
}


@media only screen and(max-width:1200px){
    .tables{
        flex-direction: column;
        row-gap: 50px;
        .market-container{
            width: 100%;
        }
        .activeorder-container{
            width: 100%;
        }

    }
}
@media only screen and(max-width:1000px){
    .top-row{
        flex-direction: column !important;
        align-items: center;
        row-gap: 50px;
    }
    .wallet-details{
        width: 100%;
        align-items: center;
    }
    .levels{
        display: none;
    }
}
</style>