<template>
    <div class="market-container">
        <h3 v-if="$route.name=='Dashboard'"> بازار </h3>
        <div class="rows">
            <div v-if="$route.name=='AdvanceTrade'" class="trade-row">
                <span> رمز ارز </span>
                <span> قیمت واحد </span>
                <span> تغییرات </span>
            </div>
            <div  @click="reD(coin.relatedCoin)"  v-for="(coin,index) in coins" :key="index" class="row">
                <div class="title">
                    <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] +'.png'" alt="" class="coin-badge">
                    <div class="text">
                        <span class="faName"> {{$coinLabel[coin.relatedCoin]}} </span>
                        <span class="Unit"> {{coin.relatedCoin}} </span>
                    </div>
                </div> 
                <span class="price"> {{$toLocal(coin.lastPrice,2)}} </span>
                <span class="change" v-if="coin.last24HourChange > 0" :class="coin.last24HourChange > 0 ? 'color-green':'fail'"> 
                                {{$toLocal(coin.last24HourChange,2)}}%+
                                <inline-svg :class="coin.last24HourChange > 0 ? 'color-green rotate':'fail'" :src="require('../../../assets/Icons/arrow.svg')" />
                </span>
                <span class="change" v-if="coin.last24HourChange < 0" :class="coin.last24HourChange > 0 ? 'color-green rotate':'fail'"> 
                                {{$toLocal(Math.abs(coin.last24HourChange),2)}}%- 
                                <inline-svg :class="coin.last24HourChange > 0 ? 'color-green rotate':'fail'" :src="require('../../../assets/Icons/arrow.svg')" />
                </span>  
                <span class="change fail" v-if="coin.last24HourChange == 0"> 0%
                    <inline-svg class="fail" :src="require('../../../assets/Icons/arrow.svg')" />
                    </span>  
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'DashMarket',
        data() {
            return {
                coinsToman:[],
                coinsTether:[],
            }
        },
        computed:{
            coins(){
                let allCoins
                if(this.$route.params.tradeTo=='TETHER'){
                    allCoins=this.coinsTether
                }else{
                    allCoins=this.coinsToman
                }
                return allCoins
            }
        },
        methods:{
            reD(coin){
                if(this.$route.name=='Dashboard'){
                    this.$router.push('/advancetrade/TETHER/'+this.$coin2Snake[coin])
                }else if(this.$route.name=='AdvanceTrade'){
                    this.$router.push('/advancetrade/'+this.$route.params.tradeTo+'/'+this.$coin2Snake[coin])
                }
            },
            async getCoins() {
                    this.state.loading = true
                    const res = await this.$axios.get('/trades/market-info',{
                        params: {
                            weeklyDataIncluded: false,
                        }
                    })
                    this.coinsToman = res.content.filter(a => a.marketType.includes('TOMAN') && a.relatedCoin !=='TOMAN')
                    this.coinsTether = res.content.filter(a => a.marketType.includes('TETHER') && a.relatedCoin !=='USDT')
            },
        },
        mounted() {
            this.getCoins();
        },
    }
</script>

<style lang="scss" scoped>
.trade-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    z-index: 3;
        top: 0;
        background: #F0F0F5;
    span{
        font-weight: 400;
        font-size: clamp(10px,1vw,12px);
        color: var(--a-hover);
        width: 80px;
    }
}





.change{
    font-weight: 500;
    font-size: clamp(10px,1vw,14px);
}
.title{
            // padding-top: 12px;
            display: flex;
            flex-direction: row;
            column-gap: 4px;
            align-items: center;
            img{
                width: 27px;
                height: 27px;
            }
            .text{
                display: flex;
                flex-direction: column;
                // row-gap: 5px;
                .faName{
                    color: var(--black);
                    font-weight: 500;
                    font-size: clamp(10px,1vw,12px);
                }
                .Unit{
                    color: rgba(132, 110, 110, 1);
                    font-weight: 500;
                    font-size: clamp(10px,1vw,12px);
                }
            }
        }
.rows{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    height: 100%;
    overflow-y: scroll;
    // padding: 4px 8px;
    
}
.row{
    transition: 700ms;
    cursor: pointer;
    max-height: 50px;
    // padding: 4px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    .title{
        width: 80px;
    }
    &:hover{
        background: #F0F0F5;
        box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    }
    span{
        width: 80px;
    }
    .price{
        color: var(--black);
        font-weight: 500;
        font-size: clamp(10px,1vw,14px);
    }
}
.market-container{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding:10px 15px;
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    width: 30%;
    max-height: 410px;
    h3{
        position: sticky;
        top: 0;
        background: #F0F0F5;
        color: var(--black);
        font-weight: 700;
        font-size: clamp(14px,2vw,16px);
    }
}
@media only screen and(max-width:400px){
    .text{
        display: none !important;
    }
}
</style>